import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import {t} from '@lib/helpers'

const Meta = dynamic(() => import('@components/Meta'))

const Custom404 = ({}) => {
  return (
    <React.Fragment>
      <Meta title={`${t('layout.Custom404.title')} – ${t('layout.title')}`} />

      <div className='Custom404'>
        <div className='BodySpace'>
          <div className='container'>
            <div className='Custom404__wrapper'>
              <h2
                className='Custom404__h2'
                dangerouslySetInnerHTML={{__html: t('layout.Custom404.h2')}}
              />

              <p className='Custom404__p'>{t('layout.Custom404.p')}</p>

              <div className='Buttons'>
                <Link href='/'>
                  <a className='Button'>{t('layout.Custom404.a')}</a>
                </Link>
              </div>
            </div>

            <img
              className='Custom404__img'
              alt='Custom404__img'
              src='/images/Custom404.svg'
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Custom404.propTypes = {}

export default Custom404
